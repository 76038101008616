export default function LoadingOverlay() {
  return (
    <div className="loading-overlay position-absolute d-flex justify-content-center align-items-center">
      <div
        className="position-absolute spinner-border text-secondary"
        role="status"
      >
        <span className="visually-hidden">Loading...</span>
      </div>
    </div>
  );
}
