import { Link } from 'react-router-dom';
import { Carrier } from '../../types';

interface Props {
  carrier: Carrier;
}

const address = (item: Carrier): JSX.Element | null => {
  const physical = [
    item.PhysicalAddressStreet,
    item.PhysicalAddressCity,
    item.PhysicalAddressState,
  ]
    .filter((item) => item !== '')
    .join(', ');

  const mailing = [
    item.MailingAddressStreet,
    item.MailingAddressCity,
    item.MailingAddressState,
  ]
    .filter((item) => item !== '')
    .join(', ');

  let $physical = null;
  if (physical) {
    $physical = (
      <p key={`physical:${item.DOTNumber}`} className="mb-1">
        {physical}
      </p>
    );
  }

  let $mailing = null;
  if (mailing && mailing !== physical) {
    $mailing = (
      <p key={`mailing:${item.DOTNumber}`} className="mb-1">
        {mailing}
      </p>
    );
  }

  if (!$mailing && !$physical) {
    return null;
  }

  return (
    <>
      {$mailing}
      {$physical}
    </>
  );
};

export default function CarrierResult({ carrier }: Props) {
  return (
    <Link
      className="list-group-item list-group-item-action border-primary"
      to={`/carrier/${carrier.DOTNumber}`}
    >
      <div className="badge badge-primary">Dot {carrier.DOTNumber}</div>
      <div className="d-flex w-100 justify-content-between">
        <div>
          <h5 className="mb-1">
            <strong>DOT Number:</strong> {carrier.DOTNumber}
          </h5>
          <h5 className="mb-1">
            <strong>LegalName:</strong> {carrier.LegalName}
          </h5>
          <h5 className="mb-1">
            <strong>DBA:</strong> {carrier.DBAName}
          </h5>
          {address(carrier)}
        </div>
        <div>
          {carrier.ContactPhone && (
            <h5 className="mb-1">
              <strong>Phone:</strong> {carrier.ContactPhone}
            </h5>
          )}
          {carrier.Fax && (
            <h5 className="mb-1">
              <strong>Fax:</strong> {carrier.Fax}
            </h5>
          )}
        </div>
      </div>
    </Link>
  );
}
