import { useState, useEffect } from 'react';
import config from '../config';
import { Carrier, Report } from '../types';

async function getReport(id: string) {
  const res = await fetch(`${config.apiRoot}/report/${id}`);
  const json = await res.json();

  return json;
}

async function getCarrier(id: string) {
  const res = await fetch(`${config.apiRoot}/carriers/${id}`);
  const json = await res.json();

  return json;
}

function exportCSVFile(items: any[], name: string) {
  const keys = Object.keys(items[0]);

  const rows = items.map((item) => {
    return keys.map((key) => `"${item[key]}"`).join(',');
  });

  // Add headers to start
  rows.unshift(keys.map((key) => `"${key}"`).join(','));

  const csv = rows.join(',');

  const fileName = name ? `${name}.csv` : 'export.csv';

  const blob = new Blob([csv], { type: 'text/csv;charset=utf-8;' });

  const link = document.createElement('a');
  if (link.download !== undefined) {
    // feature detection
    // Browsers that support HTML5 download attribute
    const url = URL.createObjectURL(blob);
    link.setAttribute('href', url);
    link.setAttribute('download', fileName);
    link.style.visibility = 'hidden';
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  }
}

type Props = {
  id: string;
};

export default function ReportComponent({ id }: Props) {
  const [report, setReport] = useState<Report>();
  const [carrier, setCarrier] = useState<Carrier>();

  useEffect(() => {
    Promise.all([getReport(id), getCarrier(id)]).then((values) => {
      const [report, carrier] = values;

      setReport(report);
      setCarrier(carrier);
    });
  }, [id]);

  if (!report || !carrier) {
    return <div>Loading...</div>;
  }

  const Carrier = carrier.Data;
  const {
    Violations,
    Inspections,
    Crashes,
    VinNumbers,
    LicensePlateNumbers,
    UnitTypeCounts,
  } = report.Data;

  return (
    <div>
      <h3>
        {Carrier.LegalName} (DOT #{Carrier.DOTNumber})
      </h3>
      <div className="row d-flex justify-content-between">
        <button
          className="btn btn-primary"
          onClick={() => {
            exportCSVFile(Violations, `${id}-violations`);
          }}
        >
          Download Violations
        </button>
        <button
          className="btn btn-primary"
          onClick={() => {
            exportCSVFile(Inspections, `${id}-inspections`);
          }}
        >
          Download Inspections
        </button>
        <button
          className="btn btn-primary"
          onClick={() => {
            exportCSVFile(Crashes, `${id}-crashes`);
          }}
        >
          Download Crashes
        </button>
      </div>
      <div className="row">
        <ul>
          <li>{Violations.length.toLocaleString()} Violations</li>
          <li>{Inspections.length.toLocaleString()} Inspections</li>
          <li>{Crashes.length.toLocaleString()} Crashes</li>
          <li>{VinNumbers.length.toLocaleString()} Unique VINs</li>
          <li>
            {LicensePlateNumbers.length.toLocaleString()} Unique Plate Numbers
          </li>
          <li>{Carrier.PowerUnits.toLocaleString()} Power Units</li>
          {UnitTypeCounts &&
            UnitTypeCounts.map((unitTypeCount: any, index: number) => {
              return (
                <li key={index}>
                  {unitTypeCount.Count.toLocaleString()}{' '}
                  {unitTypeCount.UnitType}(s)
                </li>
              );
            })}
        </ul>
      </div>
    </div>
  );
}
