import { Violation } from '../../../types';
import LoadButton from '../../LoadButton';

interface Props {
  isLoading: boolean;
  loadMore: () => void;
  violations: Violation[];
}

const violation = (violation: Violation, index: number): JSX.Element => {
  let className;
  switch (true) {
    case violation.TotalSeverityWeight >= 7:
      className = 'bg-danger text-white';
      break;
    case violation.TotalSeverityWeight >= 3:
      className = 'bg-warning';
      break;
    default:
      className = '';
      break;
  }

  return (
    <tr key={index}>
      <td>{violation.ViolationCode}</td>
      <td>{new Date(violation.InspectionDate).toLocaleDateString()}</td>
      <td>{violation.ViolationDescription}</td>
      <td className={className}>{violation.TotalSeverityWeight}</td>
    </tr>
  );
};

export default function CarrierViolations({ isLoading, loadMore, violations }: Props) {
  return (
    <div className="card">
      <div className="card-header" id="violations">
        <h5 className="mb-0">Recent Violations</h5>
      </div>
      <div id="violationsBody" aria-labelledby="violations">
        <div className="card-body">
          <table className="table table-sm table-responsive">
            <thead>
              <tr>
                <th>Violation Code</th>
                <th>Inspection Date</th>
                <th>Description</th>
                <th>Total Severity (1 - 10)</th>
              </tr>
            </thead>
            <tbody>{violations.map(violation)}</tbody>
          </table>
          <div className="row">
            <LoadButton isLoading={isLoading} onClick={(): void => loadMore()}>Load More</LoadButton>
          </div>
        </div>
      </div>
    </div>
  );
}
