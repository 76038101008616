import CarrierViolations from './CarrierViolations';
import { useAppDispatch, useAppSelector } from '../../../hooks';
import { loadCarrierViolations } from '../../../store/carriers/violations';
import { useEffect } from 'react';

interface Props {
  id: string;
}

export default function CarrierViolationsContainer({ id }: Props) {
  const dispatch = useAppDispatch();

  const state = useAppSelector(state => state.carrierViolations.violationsByCarrier[id] || {});
  const violations = state.violations || [];
  const isLoading = state.isLoading;

  useEffect(() => {
    dispatch(loadCarrierViolations({ carrierId: id }));
  }, [id]);

  return (
    <CarrierViolations
      isLoading={isLoading}
      loadMore={(): void => {
        dispatch(loadCarrierViolations({ carrierId: id }));
      }}
      violations={violations}
    />
  );
}
