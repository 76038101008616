import { useEffect } from 'react';
import format from 'date-fns/format';
import { Fatality, PBType } from '../types';
import { useAppDispatch, useAppSelector } from '../hooks';
import { loadFatalities } from '../store/fatalities';

interface Props {
  state: string;
}

const fatality = (fatality: Fatality, index: number): JSX.Element => {
  const mapUrl = `https://www.google.com/maps/search/?api=1&query=${fatality.Latitude},${fatality.Longitude}`;

  return (
    <div className="card" key={index}>
      <div className="card-body">
        <h5 className="card-title">
          Fatality at {format(new Date(fatality.Date), 'yyyy-MM-dd h:mm aa')}
        </h5>
        <dl className="row mt-3">
          <dt className="col-sm-3">Trafficway</dt>
          <dd className="col-sm-9">
            {[fatality.TrafficwayIdentifier1, fatality.TrafficwayIdentifier2]
              .filter((v) => !!v)
              .join(', ')}
          </dd>
        </dl>
        <dl className="row mt-3">
          <dt className="col-sm-3">Municipality</dt>
          <dd className="col-sm-9">
            {[fatality.City, `${fatality.County} COUNTY`, fatality.State]
              .filter((v) => !!v)
              .join(', ')}
            &nbsp;
            <a href={mapUrl} target="_blank" rel="noopener noreferrer">
              ({fatality.Latitude}, {fatality.Longitude})
            </a>
          </dd>
        </dl>
        <dl className="row mt-3">
          <dt className="col-sm-3">
            <a
              href="http://www.pedbikeinfo.org/pbcat_us/bike_images.cfm"
              target="_blank"
              rel="noopener noreferrer"
            >
              Bike Accident Description
            </a>
          </dt>
          <dd className="col-sm-9">
            {fatality.PBTypes.map((pbType: PBType) => pbType.BIKECTYPE)}
          </dd>
        </dl>
        <dl className="row mt-3">
          <dt className="col-sm-3">Work Zone Description</dt>
          <dd className="col-sm-9">{fatality.WorkZoneDescription}</dd>
        </dl>
      </div>
    </div>
  );
};

export default function StateBreakdown({ state: stateName }: Props) {
  const dispatch = useAppDispatch();

  const fatalities = useAppSelector(state => state.fatalities.byState[stateName] || []);

  useEffect(() => {
    dispatch(loadFatalities(stateName));
  }, [stateName]);

  return (
    <div>
      <h1>{stateName} Fatalities</h1>
      {fatalities.map(fatality)}
    </div>
  );
}
