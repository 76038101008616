import { SearchResult, Carrier } from '../../types';
import CarrierResult from './CarrierResult';
import LoadingOverlay from '../LoadingOverlay';

interface Props {
  results: SearchResult[];
  totalResults: number;
  isLoading: boolean;
  searchType: string;
  searchState: string;
  search: (term: string) => void;
  updateSearchType: (searchType: string) => void;
  updateSearchState: (searchState: string) => void;
}

interface ToggleProps {
  title: string;
  type: string;
}

const unitedStates: { [key: string]: string } = {
  AL: 'Alabama',
  AK: 'Alaska',
  AS: 'American Samoa',
  AZ: 'Arizona',
  AR: 'Arkansas',
  CA: 'California',
  CO: 'Colorado',
  CT: 'Connecticut',
  DE: 'Delaware',
  DC: 'District Of Columbia',
  FM: 'Federated States Of Micronesia',
  FL: 'Florida',
  GA: 'Georgia',
  GU: 'Guam',
  HI: 'Hawaii',
  ID: 'Idaho',
  IL: 'Illinois',
  IN: 'Indiana',
  IA: 'Iowa',
  KS: 'Kansas',
  KY: 'Kentucky',
  LA: 'Louisiana',
  ME: 'Maine',
  MH: 'Marshall Islands',
  MD: 'Maryland',
  MA: 'Massachusetts',
  MI: 'Michigan',
  MN: 'Minnesota',
  MS: 'Mississippi',
  MO: 'Missouri',
  MT: 'Montana',
  NE: 'Nebraska',
  NV: 'Nevada',
  NH: 'New Hampshire',
  NJ: 'New Jersey',
  NM: 'New Mexico',
  NY: 'New York',
  NC: 'North Carolina',
  ND: 'North Dakota',
  MP: 'Northern Mariana Islands',
  OH: 'Ohio',
  OK: 'Oklahoma',
  OR: 'Oregon',
  PW: 'Palau',
  PA: 'Pennsylvania',
  PR: 'Puerto Rico',
  RI: 'Rhode Island',
  SC: 'South Carolina',
  SD: 'South Dakota',
  TN: 'Tennessee',
  TX: 'Texas',
  UT: 'Utah',
  VT: 'Vermont',
  VI: 'Virgin Islands',
  VA: 'Virginia',
  WA: 'Washington',
  WV: 'West Virginia',
  WI: 'Wisconsin',
  WY: 'Wyoming',
};

const stateAbbreviations = Object.keys(unitedStates);

const listGroupItem = (item: SearchResult, index: number): JSX.Element => {
  let result: Carrier | any = null;

  result = item as Carrier;

  return <CarrierResult key={index} carrier={result} />;
};

export default function Search({
  results,
  totalResults,
  search,
  isLoading,
  searchType,
  updateSearchType,
  searchState,
  updateSearchState,
}: Props) {
  const SearchTypeToggle = (props: ToggleProps): JSX.Element => {
    const { title, type } = props;

    const active = searchType === type;

    return (
      <button
        type="button"
        className={`btn ${active ? 'btn-primary' : 'btn-outline-secondary'}`}
        onClick={(): void => updateSearchType(type)}
      >
        {title}
      </button>
    );
  };

  return (
    <div>
      <h1>Search</h1>
      <form onSubmit={(event: React.FormEvent): void => event.preventDefault()}>
        <div className="form-group">
          <label htmlFor="search">Company name, DBA name, address</label>
          <input
            type="text"
            className="form-control"
            id="search"
            aria-describedby="searchHelp"
            placeholder="Search"
            onChange={(event: React.ChangeEvent<HTMLInputElement>): void => {
              search(event.target.value);
            }}
            onKeyUp={(event: React.KeyboardEvent<HTMLInputElement>): void => {
              search(event.currentTarget.value);
            }}
          />
          <small id="searchHelp" className="form-text text-muted">
            Fuzzy search on company/carrier fields
          </small>
        </div>

        <h3>Search by...</h3>

        <div
          role="group"
          aria-label="Select search type"
          className="mb-3 d-flex flex-row"
        >
          <SearchTypeToggle title="Company Name" type="name" />
          &nbsp;
          <SearchTypeToggle title="DOT Number" type="dot" />
          &nbsp;
          <SearchTypeToggle title="License Plate" type="license" />
          &nbsp;
          {searchType === 'name' && (
            <select
              className="custom-select w-auto"
              defaultValue={searchState}
              onChange={(event: React.ChangeEvent<HTMLSelectElement>) => {
                updateSearchState(event.target.value);
              }}
            >
              <option value="">Select State</option>
              {stateAbbreviations.map((abbreviation: string, index: number) => {
                return (
                  <option key={index} value={abbreviation}>
                    {unitedStates[abbreviation]}
                  </option>
                );
              })}
            </select>
          )}
        </div>
      </form>

      <h4>
        Viewing {results.length} of {totalResults.toLocaleString()} results
      </h4>

      <div className="list-group">
        {isLoading && (
          <div className="position-relative">
            <LoadingOverlay />
          </div>
        )}
        {results && results.map(listGroupItem)}
      </div>
    </div>
  );
}
