import { ReactNode } from 'react';
import { Navigate } from 'react-router-dom';
import { useAppSelector } from '../hooks';

interface Props {
  children: ReactNode;
}

export default function NotLoggedInRoute({ children }: Props): JSX.Element {
  const isLoading = useAppSelector(state => state.auth.isLoading);
  const isLoggedIn = useAppSelector(state => state.auth.isLoggedIn);

  if (isLoading) {
    return <>...</>;
  }

  if (isLoggedIn) {
    return <Navigate to="/" replace />;
  }

  return <>{children}</>;
}
