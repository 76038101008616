import { ViolationStat } from '../../../types';

interface Props {
  violationStats: ViolationStat[];
}

const violationStat = (stat: ViolationStat, index: number): JSX.Element => {
  return (
    <tr key={index}>
      <td>#{index + 1}</td>
      <td>{stat.ViolationCode}</td>
      <td>
        {stat.ViolationDescription}: {stat.SectionDescription}
      </td>
      <td>{stat.Count}</td>
    </tr>
  );
};

export default function CarrierViolationStats({ violationStats }: Props) {
  return (
    <div className="card">
      <div className="card-header" id="violationsStats">
        <h5 className="mb-0">Top 10 Violations</h5>
      </div>
      <div id="violationsStatsBody" aria-labelledby="violationsStats">
        <div className="card-body">
          {violationStats && violationStats.length > 0 && (
            <div className="mx-auto">
              <div className="row">
                <div className="col-sm-12">
                  <table className="table table-sm table-responsive">
                    <thead>
                      <tr>
                        <th>Rank</th>
                        <th>Violation Code</th>
                        <th>Description</th>
                        <th>Count</th>
                      </tr>
                    </thead>
                    <tbody>{violationStats.map(violationStat)}</tbody>
                  </table>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}
