export default function About() {
  return (
    <div>
      <h1>About</h1>
      Check out{' '}
      <a href="https://www.bikelaneuprising.com/">bikelaneuprising.com</a> for
      awesome bike safety advocacy
      <h2>Data Sources</h2>
      <ul>
        <li>
          <a href="https://ai.fmcsa.dot.gov/SMS/Tools/Downloads.aspx">FMCSA</a>
        </li>
        <li>
          <a href="ftp://ftp.nhtsa.dot.gov/fars/">FARS</a>
        </li>
      </ul>
    </div>
  );
}
