import { Crash } from '../../../types';
import LoadButton from '../../LoadButton';

interface Props {
  crashes: Crash[];
  isLoading?: boolean;
  loadMore: () => void;
}

export default function CarrierCrashes({ crashes, isLoading, loadMore }: Props) {
  return (
    <div className="card">
      <div className="card-header" id="crashes">
        <h5 className="mb-0">Recent Crashes</h5>
      </div>
      <div id="crashesBody" aria-labelledby="crashes">
        <div className="card-body">
          <table className="table table-sm table-responsive">
            <thead>
              <tr>
                <th>Report #</th>
                <th>Report Date</th>
                <th>License Plate Number</th>
                <th>Fatalities</th>
                <th>Injuries</th>
              </tr>
            </thead>
            <tbody>
              {crashes.map((crash: Crash, index: number) => {
                return (
                  <tr key={index}>
                    <td>{crash.ReportNumber}</td>
                    <td>{new Date(crash.ReportDate).toLocaleDateString()}</td>
                    <td>{crash.VehicleLicenseNumber}</td>
                    <td>{crash.Fatalities}</td>
                    <td>{crash.Injuries}</td>
                  </tr>
                );
              })}
            </tbody>
          </table>
          <div className="row">
            <LoadButton isLoading={isLoading} onClick={(): void => loadMore()}>Load More</LoadButton>
          </div>
        </div>
      </div>
    </div>
  );
}
