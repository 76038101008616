import { useState, useEffect } from 'react';
import Search from './Search';
import { useAppDispatch, useAppSelector } from '../../hooks';
import { search, setSearchState, setSearchType } from '../../store/search';

// const updateURL = (term: string, pathname: string): void => {
//   const params = new URLSearchParams();

//   params.set('q', term);

//   window.history.replaceState({}, '', `${pathname}?${params}`);
// };

export default function SearchContainer() {
  // const location = useLocation();
  // const params = new URLSearchParams(location.search);
  // const originalTerm = params.get('q');
  // TODO WFH Put this as the default in useState() when it's working.

  const [term, setTerm] = useState('');

  const dispatch = useAppDispatch();

  const results = useAppSelector(state => state.search.results);
  const totalResults = useAppSelector(state => state.search.totalResults);
  const isLoading = useAppSelector(state => state.search.isLoading);
  const searchType = useAppSelector(state => state.search.searchType);
  const searchState = useAppSelector(state => state.search.searchState);

  useEffect(() => {
    const timer = setTimeout(() => {
      dispatch(search(term));
    }, 400);

    return (): void => {
      clearTimeout(timer);
    };
  }, [term]);

  return (
    <Search
      results={results}
      totalResults={totalResults}
      search={(term: string): void => {
        // updateURL(term, location.pathname);
        setTerm(term);
      }}
      isLoading={isLoading}
      searchType={searchType}
      updateSearchType={async (searchType: string): Promise<void> => {
        await dispatch(setSearchType(searchType));
        dispatch(search(term));
      }}
      searchState={searchState}
      updateSearchState={async (searchState: string): Promise<void> => {
        await dispatch(setSearchState(searchState));
        dispatch(search(term));
      }}
    />
  );
}
