import { useEffect } from 'react';
import MapChart from './MapChart';
import { useAppDispatch, useAppSelector } from '../../../hooks';
import { loadCrashByStateStats } from '../../../store/stats';

export default function MapChartContainer() {
  const statesSVGJSON = useAppSelector(state => state.stats.statesSVGJSON || []);
  const crashesByState = useAppSelector(state => state.stats.crashesByState);
  const crashesByStateLoaded = useAppSelector(state => state.stats.crashesByStateLoaded);
  const maxCrashCount = useAppSelector(state => state.stats.maxCrashCount);

  const dispatch = useAppDispatch();

  useEffect(() => {
    if (crashesByStateLoaded) {
      return;
    }

    (async function (): Promise<void> {
      await dispatch(loadCrashByStateStats());
    })();
  }, [crashesByStateLoaded]);

  return (
    <MapChart
      statesSVGJSON={statesSVGJSON}
      crashesByState={crashesByState}
      crashesByStateLoaded={crashesByStateLoaded}
      maxCrashCount={maxCrashCount}
    />
  );
}
