import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import callApi from '../client';
import config from '../config';
import { CarrierCrashStat, CompanyStats, StateCrashStat, StateSVGJSON } from '../types';

interface State {
  companyStats: CompanyStats;
  companyStatsLoaded: boolean;
  crashesByState: { [key: string]: StateCrashStat };
  crashesByStateLoaded: boolean;
  maxCrashCount: number;
  statesSVGJSON: StateSVGJSON[];
  carriersWithMostCrashes: CarrierCrashStat[];
  carriersWithMostCrashesLoaded: boolean;
}

const initialState: State = {
  companyStats: {
    TotalCompanies: 0,
    TotalCrashes: 0,
    TotalLicensePlates: 0,
    TotalViolations: 0
  },
  companyStatsLoaded: false,

  crashesByState: {},
  crashesByStateLoaded: false,
  maxCrashCount: 0,
  statesSVGJSON: [],

  carriersWithMostCrashes: [],
  carriersWithMostCrashesLoaded: false
};

export const loadCrashByStateStats = createAsyncThunk(
  'stats/loadByState',
  async () => {
    const statesSVGResult = await fetch('/states.json');
    const statesSVGJSON = await statesSVGResult.json();

    const crashesByStateResult = await callApi(`${config.apiRoot}/api/top-crashes-by-state`);
    const crashesByStateJSON = (await crashesByStateResult.json()) as {
      [Data: string]: Record<string, any>;
    };

    const sortedCrashes = crashesByStateJSON.data.sort(
      (a: StateCrashStat, b: StateCrashStat) => b.Count - a.Count
    );

    const maxCrashCount = sortedCrashes.length > 0 ? sortedCrashes[0]['Count'] : 0;

    const crashesByState: Record<string, StateCrashStat> = {};
    crashesByStateJSON.data.forEach((d: StateCrashStat) => {
      crashesByState[d.ReportState] = {
        Count: d.Count,
        ReportState: d.ReportState,
      };
    });

    return {
      crashesByState,
      statesSVGJSON,
      maxCrashCount
    };
  }
);

export const loadCompanyStats = createAsyncThunk(
  'stats/loadCompanyStats',
  async () => {
    const res = await callApi(`${config.apiRoot}/api/total-count-stats`);
    return await res.json();
  }
);

export const loadCarriersWithMostCrashes = createAsyncThunk(
  'stats/loadCarriesWithMostCrashes',
  async () => {
    const res = await callApi(`${config.apiRoot}/api/top-crashes-by-carrier`);
    return await res.json();
  }
);

const statsSlice = createSlice({
  name: 'stats',
  initialState,
  reducers: {},
  extraReducers: builder => {
    builder
      .addCase(loadCrashByStateStats.fulfilled, (state, action) => {
        state.crashesByState = action.payload.crashesByState;
        state.statesSVGJSON = action.payload.statesSVGJSON;
        state.maxCrashCount = action.payload.maxCrashCount;
        state.crashesByStateLoaded = true;
      })
      .addCase(loadCompanyStats.fulfilled, (state, action) => {
        state.companyStats = action.payload.data;
        state.companyStatsLoaded = true;
      })
      .addCase(loadCarriersWithMostCrashes.fulfilled, (state, action) => {
        state.carriersWithMostCrashes = action.payload.data;
        state.carriersWithMostCrashesLoaded = true;
      })
  }
});

export default statsSlice.reducer;
