import { Button, Spinner } from "react-bootstrap";

type Props = {
  isLoading?: boolean;
  onClick?: () => void;
}

export default function LoadButton({ children, onClick, isLoading }: React.PropsWithChildren<Props>) {
  return (
    <Button onClick={onClick}>
      {isLoading && (
        <Spinner animation="border" />
      )}
      {children}
    </Button>
  );
}
