import { CompanyStats } from '../../../types';

interface Props {
  companyStats: CompanyStats;
}

export default function Stats({ companyStats }: Props) {
  let companies = '...';
  let crashes = '...';
  let violations = '...';
  let plates = '...';

  if (Object.keys(companyStats).length > 0) {
    companies = companyStats.TotalCompanies.toLocaleString();
    crashes = companyStats.TotalCrashes.toLocaleString();
    violations = companyStats.TotalViolations.toLocaleString();
    plates = companyStats.TotalLicensePlates.toLocaleString();
  }

  return (
    <div className="w-100 mb-3">
      <div className="d-flex flex-row justify-content-center flex-wrap">
        <div className="px-3 text-center">
          <h4>Companies</h4>
          <h3>{companies}</h3>
        </div>
        <div className="px-3 text-center">
          <h4>Crashes</h4>
          <h3>{crashes}</h3>
        </div>
        <div className="px-3 text-center">
          <h4>Violations</h4>
          <h3>{violations}</h3>
        </div>
        <div className="px-3 text-center">
          <h4>License Plates</h4>
          <h3>{plates}</h3>
        </div>
      </div>
    </div>
  );
}
