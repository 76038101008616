import { useEffect } from 'react';
import Carrier from './Carrier';
import { Carrier as CarrierType } from '../../types';
import { useAppDispatch, useAppSelector } from '../../hooks';
import { loadCarrier } from '../../store/carriers';

interface Props {
  id: string;
}

export default function CarrierContainer({ id }: Props) {
  const carrierData = useAppSelector(state => state.carriers.all[id]);

  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(loadCarrier(id));
  }, [id]);

  return <Carrier id={id} carrierData={carrierData || ({} as CarrierType)} />;
}
