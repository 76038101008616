import { useEffect } from 'react';
import Stats from './Stats';
import { useAppDispatch, useAppSelector } from '../../../hooks';
import { loadCompanyStats } from '../../../store/stats';

export default function StatsContainer() {
  const companyStats = useAppSelector(state => state.stats.companyStats);
  const companyStatsLoaded = useAppSelector(state => state.stats.companyStatsLoaded);

  const dispatch = useAppDispatch();

  useEffect(() => {
    if (companyStatsLoaded) {
      return;
    }

    (async (): Promise<void> => {
      await dispatch(loadCompanyStats());
    })();
  }, [companyStatsLoaded]);

  return <Stats companyStats={companyStats} />;
}
