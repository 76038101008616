import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { RootState } from '..';
import callApi from '../../client';
import config from '../../config';
import { Crash } from '../../types';

type CarrierCrashesState = {
  crashes: Crash[];
  isLoading: boolean;
  after: string;
}

interface State {
  crashesByCarrier: Record<string, CarrierCrashesState>;
}

type APIResponse = {
  data: Crash[];
  after: string;
}

const initialState: State = {
  crashesByCarrier: {}
};

export const loadCarrierCrashes = createAsyncThunk<APIResponse, { carrierId: string }, { state: RootState }>(
  'carriers/crashes/load',
  async ({ carrierId }, { getState }) => {
    const state = getState();
    const urlParams = new URLSearchParams();
    if (state.carrierCrashes.crashesByCarrier?.[carrierId]?.after) {
      urlParams.set('after', state.carrierCrashes.crashesByCarrier?.[carrierId]?.after);
    }
    urlParams.set('limit', '5')

    const res = await callApi(`${config.apiRoot}/api/carriers/${carrierId}/crashes?${urlParams}`);
    const json = await res.json();

    return json;
  }
);

const carriersCrashesSlice = createSlice({
  name: 'carriers/crashes',
  initialState,
  reducers: {},
  extraReducers: builder => {
    builder
      .addCase(loadCarrierCrashes.pending, (state, action) => {
        if (!state.crashesByCarrier[action.meta.arg.carrierId]) {
          state.crashesByCarrier[action.meta.arg.carrierId] = {
            after: '',
            crashes: [],
            isLoading: true
          };
        }
      })
      .addCase(loadCarrierCrashes.fulfilled, (state, action) => {
        const existing = state.crashesByCarrier[action.meta.arg.carrierId].crashes;
        state.crashesByCarrier[action.meta.arg.carrierId].crashes = [...existing, ...action.payload.data];
        state.crashesByCarrier[action.meta.arg.carrierId].after = action.payload.after;
        state.crashesByCarrier[action.meta.arg.carrierId].isLoading = false;
      })
      .addCase(loadCarrierCrashes.rejected, (state, action) => {
        state.crashesByCarrier[action.meta.arg.carrierId].isLoading = false;
      })
  }
});

export default carriersCrashesSlice.reducer;
