import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import callApi from '../client';
import config from '../config';
import { Fatality } from '../types';

interface State {
  byState: Record<string, Fatality[]>;
}

const initialState: State = {
  byState: {}
};

export const loadFatalities = createAsyncThunk(
  'fatalities/load',
  async (state: string) => {
    const res = await callApi(`${config.apiRoot}/api/fatalities?state=${state}`);
    const json = await res.json();

    return json.data;
  }
);

const fatalitiesSlice = createSlice({
  name: 'fatalities',
  initialState,
  reducers: {},
  extraReducers: builder => {
    builder
      .addCase(loadFatalities.fulfilled, (state, action) => {
        state.byState[action.meta.arg] = action.payload;
      })
  }
});

export default fatalitiesSlice.reducer;
