import { useEffect } from 'react';
import CrashStats from './CrashStats';
import { useAppDispatch, useAppSelector } from '../../../hooks';
import { loadCarriersWithMostCrashes } from '../../../store/stats';

export default function CrashStatsContainer() {
  const carriersWithMostCrashes = useAppSelector(state => state.stats.carriersWithMostCrashes);
  const carriersWithMostCrashesLoaded = useAppSelector(state => state.stats.carriersWithMostCrashesLoaded);

  const dispatch = useAppDispatch();

  useEffect(() => {
    if (carriersWithMostCrashesLoaded) {
      return;
    }

    (async (): Promise<void> => {
      await dispatch(loadCarriersWithMostCrashes());
    })();
  }, [carriersWithMostCrashesLoaded]);

  return <CrashStats carriersWithMostCrashes={carriersWithMostCrashes} />;
}
