import CrashStatsContainer from './CrashStats/CrashStatsContainer';
import MapChartContainer from './MapChart/MapChartContainer';
import StatsContainer from './Stats/StatsContainer';

export default function Home() {
  return (
    <div>
      <div className="d-flex flex-wrap mb-3">
        <StatsContainer />
        <div className="d-flex flex-column mx-auto">
          <div>
            <CrashStatsContainer />
          </div>
        </div>
      </div>
      <MapChartContainer />
    </div>
  );
}
