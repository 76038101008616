import { configureStore } from '@reduxjs/toolkit';
import authReducer from './auth';
import carriersReducer from './carriers';
import carrierCrashesReducer from './carriers/crashes';
import carrierViolationsReducers from './carriers/violations';
import fatalitiesReducer from './fatalities';
import searchReducer from './search';
import statsReducer from './stats';

const store = configureStore({
  reducer: {
    auth: authReducer,
    carriers: carriersReducer,
    carrierCrashes: carrierCrashesReducer,
    carrierViolations: carrierViolationsReducers,
    fatalities: fatalitiesReducer,
    search: searchReducer,
    stats: statsReducer,
  }
});

export default store;

// Infer the `RootState` and `AppDispatch` types from the store itself.
export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
