import { Carrier } from '../../types';
import CarrierViolationsContainer from './CarrierViolations/CarrierViolationsContainer';
import CarrierCrashesContainer from './CarrierCrashes/CarrierCrashesContainer';
import CarrierViolationsStatsContainer from './CarrierViolationStats/CarrierViolationStatsContainer';
import { useEffect } from 'react';

interface Props {
  id: string;
  carrierData: Carrier;
}

const buildPhysicalAddress = (source: Carrier): string => {
  const address = [
    source.PhysicalAddressStreet,
    source.PhysicalAddressCity,
    source.PhysicalAddressState,
    source.PhysicalAddressZip,
  ]
    .filter((item) => !!item)
    .join(', ');

  return address;
};

const buildMailingAddress = (source: Carrier): string => {
  const address = [
    source.MailingAddressStreet,
    source.MailingAddressCity,
    source.MailingAddressState,
    source.MailingAddressZip,
  ]
    .filter((item) => !!item)
    .join(', ');

  return address;
};

export default function CarrierComponent({ id, carrierData }: Props) {
  let physicalAddress = '';
  let mailingAddress = '';
  if (carrierData) {
    physicalAddress = buildPhysicalAddress(carrierData);
    mailingAddress = buildMailingAddress(carrierData);
  }

  return (
    <div>
      <h1>{carrierData.LegalName}</h1>
      <h5>USDOT {id}</h5>
      <dl className="row mt-3">
        <dt className="col-sm-3">DBA</dt>
        <dd className="col-sm-9">{carrierData.DBAName}</dd>

        <dt className="col-sm-3">Physical Address</dt>
        <dd className="col-sm-9">
          {physicalAddress && (
            <a
              target="_blank"
              rel="noopener noreferrer"
              href={`https://www.google.com/maps/search/?api=1&query=${encodeURIComponent(
                physicalAddress
              )}`}
            >
              {physicalAddress}
            </a>
          )}
        </dd>

        <dt className="col-sm-3">Mailing Address</dt>
        <dd className="col-sm-9">
          {mailingAddress && (
            <a
              target="_blank"
              rel="noopener noreferrer"
              href={`https://www.google.com/maps/search/?api=1&query=${encodeURIComponent(
                mailingAddress
              )}`}
            >
              {mailingAddress}
            </a>
          )}
        </dd>

        <dt className="col-sm-3">Phone</dt>
        <dd className="col-sm-9">{carrierData.ContactPhone}</dd>

        <dt className="col-sm-3">Email</dt>
        <dd className="col-sm-9">{carrierData.ContactEmail}</dd>

        <dt className="col-sm-3">Oversight Office State</dt>
        <dd className="col-sm-9">{carrierData.OversightOfficeState}</dd>

        <dt className="col-sm-3">Drivers</dt>
        <dd className="col-sm-9">
          {carrierData.TotalDrivers
            ? carrierData.TotalDrivers.toLocaleString()
            : ''}
        </dd>

        <dt className="col-sm-3">Power Units</dt>
        <dd className="col-sm-9">
          {carrierData.TotalPowerUnits
            ? carrierData.TotalPowerUnits.toLocaleString()
            : ''}
        </dd>

        <dt className="col-sm-3">
          <a
            target="_blank"
            rel="noopener noreferrer"
            href="https://www.fmcsa.dot.gov/registration/form-mcs-150-and-instructions-motor-carrier-identification-report"
          >
            MCS 150
          </a>
          &nbsp;Mileage
        </dt>
        <dd className="col-sm-9">
          {carrierData.MCSMileage
            ? carrierData.MCSMileage.toLocaleString()
            : ''}
        </dd>

        <dt className="col-sm-3">Total FMCSA Violations</dt>
        <dd className="col-sm-9">
          {carrierData.TotalViolations
            ? carrierData.TotalViolations.toLocaleString()
            : ''}
        </dd>

        <dt className="col-sm-3">Total FMCSA Crashes</dt>
        <dd className="col-sm-9">
          {carrierData.TotalCrashes
            ? carrierData.TotalCrashes.toLocaleString()
            : ''}
        </dd>

        <dt className="col-sm-3">Total FMCSA License Plates</dt>
        <dd className="col-sm-9">
          {carrierData.TotalLicensePlates
            ? carrierData.TotalLicensePlates.toLocaleString()
            : ''}
        </dd>

        <dt className="col-sm-3">Total FMCSA Fatalities</dt>
        <dd className="col-sm-9">
          {carrierData.FMCSAFatalities
            ? carrierData.FMCSAFatalities.toLocaleString()
            : ''}
        </dd>
      </dl>

      <CarrierViolationsStatsContainer id={id} />

      <CarrierCrashesContainer id={id} />

      <CarrierViolationsContainer id={id} />
    </div>
  );
}
