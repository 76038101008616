import CarrierCrashes from './CarrierCrashes';
import { loadCarrierCrashes } from '../../../store/carriers/crashes';
import { useAppDispatch, useAppSelector } from '../../../hooks';
import { useEffect } from 'react';

interface Props {
  id: string;
}

export default function CarrierCrashesContainer({ id }: Props) {
  const dispatch = useAppDispatch();

  const state = useAppSelector(state => state.carrierCrashes.crashesByCarrier[id] || {});
  const crashes = state.crashes || [];
  const isLoading = state.isLoading;

  useEffect(() => {
    dispatch(loadCarrierCrashes({ carrierId: id }));
  }, [id]);

  return (
    <CarrierCrashes
      crashes={crashes}
      isLoading={isLoading}
      loadMore={(): void => {
        dispatch(loadCarrierCrashes({ carrierId: id }));
      }}
    />
  );
}
