import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import callApi from '../client';
import config from '../config';
import { Carrier, Violation } from '../types';

interface State {
  all: { [key: string]: Carrier };
  byPage: { [key: string]: string[] };
  limit: number;
  loading: boolean;
  page: number;
  totalPages: number;
  violationsByCarrier: { [key: string]: Violation[] };
}

const initialState: State = {
  all: {},
  byPage: {},
  limit: 0,
  loading: false,
  page: 0,
  totalPages: 0,
  violationsByCarrier: {}
};

export const loadCarriers = createAsyncThunk(
  'carriers/all',
  async ({ page, limit }: { page: number, limit: number }) => {
    const res = await callApi(`${config.apiRoot}/api/carriers?after=${(page - 1) * limit}&limit=${limit}`);
    const json = await res.json();

    return json.data;
  }
);

export const loadCarrier = createAsyncThunk(
  'carriers/get',
  async (id: string) => {
    const res = await callApi(`${config.apiRoot}/api/carriers/${id}`);
    const json = await res.json();

    return json.data;
  }
)

const carriersSlice = createSlice({
  name: 'carriers',
  initialState,
  reducers: {},
  extraReducers: builder => {
    builder
      .addCase(loadCarrier.fulfilled, (state, action) => {
        state.all[action.payload.DOTNumber] = action.payload;
      })
      .addCase(loadCarriers.pending, (state, action) => {
        state.loading = true;
        state.page = action.meta.arg.page;
      })
      .addCase(loadCarriers.fulfilled, (state, action) => {
        action.payload.results.forEach((result: Carrier) => {
          state.all[result.DOTNumber] = result;
        });

        state.byPage[state.page] = action.payload.results.map(
          (result: Carrier) => result.DOTNumber
        );

        state.loading = false;
        state.totalPages = Math.ceil(action.payload.Meta.Total / action.meta.arg.limit);
      })
  }
});

export default carriersSlice.reducer;
