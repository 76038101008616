import { useEffect } from 'react';
import { useAppDispatch, useAppSelector } from '../../../hooks';
import { loadCarrierViolationStats } from '../../../store/carriers/violations';
import CarrierViolationStats from './CarrierViolationStats';

interface Props {
  id: string;
}

export default function CarrierViolationsStatsContainer({ id }: Props) {
  const dispatch = useAppDispatch();

  const stats = useAppSelector(state => state.carrierViolations.violationsStatsByCarrier[id] || {});

  useEffect(() => {
    dispatch(loadCarrierViolationStats({ carrierId: id }));
  }, [id]);

  return <CarrierViolationStats violationStats={stats} />;
}
