import { NavLink, Routes, Navigate, Route, useParams, HashRouter } from 'react-router-dom';
import classNames from 'classnames';
import Home from './Home/Home';
import CarrierContainer from './Carrier/CarrierContainer';
import About from './About';
import Login from './Login';
import StateBreakdown from './StateBreakdown';
import Report from './Report';
import SearchContainer from './Search/SearchContainer';
import PrivateRoute from './PrivateRoute';
import NotLoggedInRoute from './NotLoggedInRoute';
import { logOut } from '../store/auth';
import { useAppDispatch, useAppSelector } from '../hooks';
import './App.scss';
import { Container, Nav, Navbar, NavDropdown } from 'react-bootstrap';
import logo from './Uprise logo.jpg';

function ReportWrapper() {
  const { id } = useParams();

  if (!id) {
    return <Navigate to="/" replace />;
  }

  return <Report id={id} />;
}

function StateBreakdownWrapper() {
  const { id } = useParams();

  if (!id) {
    return <Navigate to="/" replace />;
  }

  return <StateBreakdown state={id} />;
}

function CarrierContainerWrapper() {
  const { id } = useParams();

  if (!id) {
    return <Navigate to="/carriers" replace />;
  }

  return <CarrierContainer id={id} />;
}

export default function App() {
  const dispatch = useAppDispatch();

  const isLoading = useAppSelector(state => state.auth.isLoading);
  const isLoggedIn = useAppSelector(state => state.auth.isLoggedIn);
  const userEmail = useAppSelector(state => state.auth.user?.email);

  return (
    <HashRouter>
      <div className="app">
        <Navbar expand="md">
          <Container>
            <Navbar.Brand href="/#">
              <img src={logo} alt="Uprise logo" height="60" />
            </Navbar.Brand>
            <Navbar.Toggle aria-controls="basic-navbar-nav" />
            <Navbar.Collapse className="justify-content-end">
              <Nav>
                {isLoggedIn && (
                  <>
                    <Nav.Link>
                      <NavLink className={({ isActive }) => classNames({ 'active': isActive }, 'nav-link')} end to={'/search'}>
                        Search
                      </NavLink>
                    </Nav.Link>
                    <Nav.Link>
                      <NavLink className={({ isActive }) => classNames({ 'active': isActive }, 'nav-link')} end to={'/about'}>
                        About
                      </NavLink>
                    </Nav.Link>
                    <Navbar.Text>
                      <NavDropdown title={userEmail} >
                        <NavDropdown.Item onClick={() => {
                          dispatch(logOut());
                        }}>
                          Sign Out
                        </NavDropdown.Item>
                      </NavDropdown>
                    </Navbar.Text>
                  </>
                )}
              </Nav>
            </Navbar.Collapse>
          </Container>
        </Navbar>

        <div className="container">
          {isLoading ? (
            <div>...</div>
          ) : (
            <Routes>
              <Route path="/search" element={<PrivateRoute><SearchContainer /></PrivateRoute>} />
              <Route path="/carrier/:id" element={<PrivateRoute><CarrierContainerWrapper /></PrivateRoute>} />
              <Route path="/states/:id" element={<PrivateRoute><StateBreakdownWrapper /></PrivateRoute>} />
              <Route path="/report/:id" element={<PrivateRoute><ReportWrapper /></PrivateRoute>} />
              <Route path="/about" element={<PrivateRoute><About /></PrivateRoute>} />
              <Route path="/" element={<PrivateRoute><Home /></PrivateRoute>} />
              <Route path="/login" element={<NotLoggedInRoute><Login /></NotLoggedInRoute>} />
            </Routes>
          )}
        </div>
      </div>
    </HashRouter>
  );
}
