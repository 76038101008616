import { useState } from 'react';
import { Link } from 'react-router-dom';
import './CrashStats.scss';
import { CarrierCrashStat } from '../../../types';

interface Props {
  carriersWithMostCrashes: CarrierCrashStat[];
}

export default function CrashStats({ carriersWithMostCrashes }: Props) {
  const [show, setShow] = useState<boolean>(false);

  const tableRows = (): JSX.Element[] => {
    return carriersWithMostCrashes.map(
      (stat: CarrierCrashStat, index: number): JSX.Element => {
        return (
          <tr key={index}>
            <td>
              <Link to={`/carrier/${stat.DOTNumber}`}>{stat.LegalName}</Link>
            </td>
            <td>{stat.Count.toLocaleString()}</td>
          </tr>
        );
      }
    );
  };

  const title = `Top ${carriersWithMostCrashes.length} Companies by Crashes`;

  return (
    <div className="crashStats mx-auto card">
      <div className="card-header text-center">
        <h4 className="mb-0">
          <button className="btn btn-link" onClick={(): void => setShow(!show)}>
            {title}
          </button>
        </h4>
      </div>
      {show && (
        <div className="card-body">
          <table className="table-sm">
            <thead className="text-primary">
              <tr>
                <th>Company</th>
                <th># Crashes</th>
              </tr>
            </thead>
            <tbody>{tableRows()}</tbody>
          </table>
        </div>
      )}
    </div>
  );
}
