import ReactDOM from 'react-dom';
import { StateCrashStat } from '../../../types';
import './Tooltip.scss';

export interface Props {
  x: number;
  y: number;
  stat: StateCrashStat;
  element?: HTMLDivElement | null;
}

export default function Tooltip({ x, y, stat, element }: Props) {
  if (!stat || !element) {
    return null;
  }

  return ReactDOM.createPortal(
    // Potentially this could all be rendered by the parent instead.
    <div
      className="caption"
      style={{
        display: 'block',
        left: x,
        top: y,
      }}
    >
      <div>{stat.ReportState}</div>
      <div>Crashes: {stat.Count.toLocaleString()}</div>
    </div>,
    element
  );
}
