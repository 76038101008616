import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { AuthState, User } from '../types';

const initialState: AuthState = {
  isLoading: true,
  isLoggedIn: false
};

export const logOut = createAsyncThunk(
  'auth/logOut',
  async () => {
    const res = await fetch('/api/auth/logout');

    if (res.status !== 200) {
      throw new Error('invalid API response');
    }

    return null;
  }
);

export const checkAuthStatus = createAsyncThunk(
  'auth/check',
  async (): Promise<User> => {
    const res = await fetch('/api/auth/info');

    if (res.status !== 200) {
      throw new Error('invalid API response');
    }

    const json = await res.json();
    return json;
  }
);

const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {},
  extraReducers: builder => {
    builder
      .addCase(checkAuthStatus.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(checkAuthStatus.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isLoggedIn = true;
        state.user = action.payload;
      })
      .addCase(checkAuthStatus.rejected, (state, action) => {
        state.isLoading = false;
        state.isLoggedIn = false;
        state.user = undefined;
      })
      .addCase(logOut.fulfilled, (state) => {
        state.isLoading = false;
        state.isLoggedIn = false;
        state.user = undefined;
      })
  }
});

export default authSlice.reducer;
