import { useEffect, ReactNode } from 'react';
import { useAppDispatch } from '../hooks';
import { checkAuthStatus } from '../store/auth';

interface Props {
  children: ReactNode;
}

export default function AuthenticatedApp({ children }: Props) {
  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(checkAuthStatus());
  }, []);

  return <>{children}</>;
}
