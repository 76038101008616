import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { RootState } from '..';
import callApi from '../../client';
import config from '../../config';
import { Violation, ViolationStat } from '../../types';

type CarrierViolationsState = {
  violations: Violation[];
  isLoading: boolean;
  after: string;
}

interface State {
  violationsByCarrier: Record<string, CarrierViolationsState>;
  violationsStatsByCarrier: Record<string, ViolationStat[]>;
}

type APIResponse = {
  data: Violation[];
  after: string;
}

const initialState: State = {
  violationsByCarrier: {},
  violationsStatsByCarrier: {}
};

export const loadCarrierViolations = createAsyncThunk<APIResponse, { carrierId: string }, { state: RootState }>(
  'carriers/violations/load',
  async ({ carrierId }, { getState }) => {
    const state = getState();
    const urlParams = new URLSearchParams();
    if (state.carrierViolations.violationsByCarrier?.[carrierId]?.after) {
      urlParams.set('after', state.carrierViolations.violationsByCarrier?.[carrierId]?.after);
    }
    urlParams.set('limit', '5')

    const res = await callApi(`${config.apiRoot}/api/carriers/${carrierId}/violations?${urlParams}`);
    const json = await res.json();

    return json;
  }
);

export const loadCarrierViolationStats = createAsyncThunk(
  'carriers/violations/stats/load',
  async ({ carrierId }: { carrierId: string }) => {
    const res = await callApi(`${config.apiRoot}/api/carriers/${carrierId}/violations/stats`);
    const json = await res.json();

    return json.data;
  }
);

const carriersViolationsSlice = createSlice({
  name: 'carriers/violations',
  initialState,
  reducers: {},
  extraReducers: builder => {
    builder
    .addCase(loadCarrierViolations.pending, (state, action) => {
      if (!state.violationsByCarrier[action.meta.arg.carrierId]) {
        state.violationsByCarrier[action.meta.arg.carrierId] = {
          after: '',
          violations: [],
          isLoading: true
        };
      }
    })
    .addCase(loadCarrierViolations.fulfilled, (state, action) => {
      const existing = state.violationsByCarrier[action.meta.arg.carrierId].violations;
      state.violationsByCarrier[action.meta.arg.carrierId].violations = [...existing, ...action.payload.data];
      state.violationsByCarrier[action.meta.arg.carrierId].after = action.payload.after;
      state.violationsByCarrier[action.meta.arg.carrierId].isLoading = false;
    })
    .addCase(loadCarrierViolations.rejected, (state, action) => {
      state.violationsByCarrier[action.meta.arg.carrierId].isLoading = false;
    })
    .addCase(loadCarrierViolationStats.fulfilled, (state, action) => {
      state.violationsStatsByCarrier[action.meta.arg.carrierId] = [...action.payload];
    })
  }
});

export default carriersViolationsSlice.reducer;
