import { useState, ReactNode, MouseEvent, useRef } from 'react';
import { interpolate as d3interpolate } from 'd3-interpolate';
import './MapChart.scss';
import { StateSVGJSON, StateCrashStat } from '../../../types';
import Tooltip, { Props as TooltipProps } from './Tooltip';
import { useNavigate } from 'react-router';

const color = (value: number, max: number): string => {
  return d3interpolate('#ddd', '#9a2617')(value / max);
};

export interface Props {
  statesSVGJSON: StateSVGJSON[];
  crashesByState: { [key: string]: StateCrashStat };
  crashesByStateLoaded: boolean;
  maxCrashCount: number;
}

// http://bl.ocks.org/NPashaP/a74faf20b492ad377312
export default function MapChart({
  statesSVGJSON,
  crashesByState,
  crashesByStateLoaded,
  maxCrashCount,
}: Props) {
  const navigate = useNavigate();
  const tooltipRef = useRef(null);

  const [tooltipProps, setTooltipProps] = useState<TooltipProps>();

  const paths = (): ReactNode[] => {
    return statesSVGJSON.map((stateSVGJSON: StateSVGJSON, index: number) => {
      const crashCount = crashesByState[stateSVGJSON.id]
        ? crashesByState[stateSVGJSON.id].Count
        : 0;

      return (
        <path
          key={index}
          className="state"
          fill={color(crashCount, maxCrashCount)}
          stroke="#fff"
          strokeWidth="6px"
          d={stateSVGJSON.d}
          onMouseOver={(event: MouseEvent<SVGPathElement>): void => {
            setTooltipProps({
              x: event.pageX,
              y: event.pageY - 28, // Arbitrary offset for better UI.
              stat: crashesByState[stateSVGJSON.id],
            });
          }}
          onMouseOut={(): void => {
            setTooltipProps(undefined);
          }}
          onClick={(): void => {
            setTooltipProps(undefined);

            navigate(`/states/${stateSVGJSON.name}`);
          }}
        />
      );
    });
  };

  return (
    <div className="map">
      <h4>Crashes By State</h4>
      <div ref={tooltipRef}></div>
      <svg viewBox="0 0 960 600">{crashesByStateLoaded ? paths() : null}</svg>
      {tooltipProps && (
        <Tooltip x={tooltipProps?.x} y={tooltipProps.y} stat={tooltipProps.stat} element={tooltipRef.current} />
      )}
    </div>
  );
}
