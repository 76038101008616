import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '.';
import callApi from '../client';
import config from '../config';
import { SearchResult } from '../types';

interface State {
  results: SearchResult[];
  totalResults: number;
  term: string;
  isLoading: boolean;
  searchType: string;
  searchState: string;
}

const initialState: State = {
  results: [],
  term: '',
  totalResults: 0,
  isLoading: false,
  searchType: 'name',
  searchState: ''
};

export const search = createAsyncThunk(
  'search',
  async (term: string, thunkApi) => {
    const state = thunkApi.getState() as RootState;

    const params = new URLSearchParams();
    params.set('term', term);
    params.set('searchType', state.search.searchType);
    // For now, only include this for company name searches.
    if (state.search.searchType === 'name') {
      params.set('searchState', state.search.searchState);
    }

    const url = `${config.apiRoot}/api/search?${params}`;

    const res = await callApi(url);
    const json = await res.json();

    return {
      results: json.data,
      totalResults: json.meta.total
    };
  }
);

const searchSlice = createSlice({
  name: 'search',
  initialState,
  reducers: {
    setSearchState: (state, action: PayloadAction<string>) => {
      state.searchState = action.payload;
    },
    setSearchType: (state, action: PayloadAction<string>) => {
      state.searchType = action.payload;
    }
  },
  extraReducers: builder => {
    builder
      .addCase(search.pending, (state, action) => {
        if (action.meta.arg === '') {
          state.term = '';
          state.results = [];
          state.totalResults = 0;
          state.isLoading = false;
          return;
        }

        state.results = [];
        state.term = action.meta.arg;
        state.totalResults = 0;
        state.isLoading = true;
      })
      .addCase(search.fulfilled, (state, action) => {
        state.results = action.payload.results;
        state.totalResults = action.payload.totalResults || 0;
        state.isLoading = false;
      })
  }
});

const { setSearchState, setSearchType } = searchSlice.actions;

export {
  setSearchState,
  setSearchType
};

export default searchSlice.reducer;
